import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./MediaRecord.css";
import socketManager from "../socketManager/SocketManager";
import useWebRTC from "../WebrtcConnection/useWebrtc.js";
import axios from "axios";
import Micon from "../assets/Micon";
import MicOff from "../assets/MicOff";
import SwitchCamera from "../assets/SwitchCamera";
import { thirdPartServerUrl } from "../config/keys";
import ReactLoading from "react-loading";
import { asyncStorage } from "./asyncStorage.js";

// const CHUNK_SIZE = 10 * 1024 * 1024; // 10 MB chunks
// const RECORDING_DURATION = 60 * 10000; // 1 minute

// Create an Axios instance
const noBaseUrlInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to prepend the base URL and manage headers
noBaseUrlInstance.interceptors.request.use((config) => {
  config.url = thirdPartServerUrl + config.url;
  config.headers = {
    ...config.headers,
  };
  return config;
});

let Count = 0;

const MediaRecord = () => {
  const [recording, setRecording] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [facingMode, setFacingMode] = useState("user");
  const [userList, setUserList] = useState([]);
  const [previousSended, setPreviousSended] = useState([]);
  const [localStream, setLocalStream] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const localVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const totalChunksRef = useRef(0);
  const chunkIndexRef = useRef(0);
  const stopUploadRef = useRef(false);
  const streamRef = useRef(null);
  const peerConnections = useRef({});
  const { artistId, contentId, mainroomname, isAudio, isFlip } = useParams();
  const navigate = useNavigate();
  const filenameRef = useRef(
    `recording_${Math.random().toString(36).substring(2, 15)}`
  ); // Generate a unique filename only once
  const [isSwitching, setIsSwitching] = useState(false);

  const isEnableAudio = isAudio === "true" ? true : false;
  const isEnableFlip = isFlip === "user" ? false : true;

  const [mute, setMute] = useState(isEnableAudio);
  const [isBack, setIsBack] = useState(isEnableFlip);
  const [videoNo, setVideoNo] = useState(0);
  const [hideSwitch, setHideSwitch] = useState(false);
  const chunkQueue = useRef([]);
  const [endLoading, setEndLoading] = useState(false);

  const isStreamStrart = artistId && mainroomname;

  const {
    handleSignalingData,
    createPeerConnectionCreator,
    // toggleAudio,
    closeAllPeerConnections,
  } = useWebRTC();

  const handleJoinedRoom = useCallback(
    async (userId) => {
      if (localStream && mainroomname) {
        await createPeerConnectionCreator(
          localStream,
          userId,
          mainroomname,
          peerConnections
        );
      }
    },
    [createPeerConnectionCreator, localStream, mainroomname]
  );

  const handleReceviedAnswer = useCallback(
    async (userId, answer, liveRoom) => {
      if (mainroomname && liveRoom && mainroomname === liveRoom) {
        await handleSignalingData({
          userId,
          answer,
          type: "answer",
          peerConnections,
        });
      }
    },
    [handleSignalingData, mainroomname]
  );

  const handleReceviedCandidate = useCallback(
    async (userId, candidate, liveRoom) => {
      if (mainroomname && liveRoom && mainroomname === liveRoom) {
        await handleSignalingData({
          userId,
          candidate,
          type: "icecandidate",
          peerConnections,
        });
      }
    },
    [handleSignalingData, mainroomname]
  );

  const handleOnline = () => {
    setIsOnline(true);
    window.location.reload();
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    // Listen for online and offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  console.log("isOnline", isOnline);

  const handlegoLiveOffer = useCallback(
    async (data) => {
      // setMute(data?.isAudio);
      // setIsBack(data?.isFlip);
      if (data?.userList.length > 0) {
        data?.userList.forEach(async (user) => {
          if (
            user?.mainRoom === data?.mainRoom &&
            data?.createrId !== user?.userSocketId
          ) {
            await createPeerConnectionCreator(
              localStream,
              user?.userSocketId,
              mainroomname,
              peerConnections
            );
          }
        });
      }
    },
    [createPeerConnectionCreator, localStream, mainroomname]
  );

  const handleUserUpdateList = useCallback((mainUserList) => {
    // console.log("main user list ", mainUserList);
    // setUserList(mainUserList)
  }, []);

  const handleLiveUserUpdateList = useCallback((mainUserList, isBefore) => {
    if (isBefore) {
      setUserList(mainUserList);
    }
  }, []);

  const handleAudio = useCallback(() => {
    if (localStream) {
      if (mute) {
        // Mute: Replace the audio track with a silent track
        toggleAudio(true, localStream);
      } else {
        // Unmute: Restore the original audio track
        toggleAudio(false, localStream);
      }
      setMute((prevMute) => !prevMute); // Update mute state
    } else {
      console.log("Mute toggle failed: No local stream available");
    }
  }, [localStream, mute]);

  // Function to toggle audio (mute/unmute)
  const toggleAudio = (isMuted, stream) => {
    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length > 0) {
      audioTracks[0].enabled = !isMuted; // Disable or enable the audio track
    }
  };

  // Function to handle chunk upload
  const uploadChunk = useCallback(
    async (chunk, index, filename, totalChunks, camType, isSilent) => {
      if (stopUploadRef.current) return;

      console.log(index, "Uploading chunk", isSilent);

      const formData = new FormData();
      formData.append("video", chunk); // Upload blob directly
      formData.append("filename", filename);
      formData.append("chunkNumber", index); // Chunk index
      formData.append("contentTd", contentId); // Add contentId for backend reference
      formData.append("totalChunks", totalChunks); // Total number of chunks
      formData.append("camType", camType);

      try {
        const response = await axios.post("/api/upload-chunk", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response?.data?.success) {
          console.log(`Chunk ${index} uploaded successfully`, response.data);
        } else {
          console.warn(`Chunk ${index} upload failed or duplicate`);
        }
      } catch (error) {
        console.error("Upload error:", error);
      }
    },
    [contentId]
  );

  // Function to process the chunk queue
  const processQueue = useCallback(async () => {
    console.log(
      "Processing chunk queue...",
      chunkQueue.current.length,
      "stopUploadRef.current",
      stopUploadRef.current
    );
    if (chunkQueue.current.length === 0 || stopUploadRef.current) return;

    const { chunk, index, filename, totalChunks, camType, isSilent } =
      chunkQueue.current.shift();

    await uploadChunk(chunk, index, filename, totalChunks, camType, isSilent);

    if (chunkQueue.current.length > 0) {
      processQueue();
    }
  }, [uploadChunk]);

  // Start recording video chunks
  const startRecording = useCallback(
    async (stream, filename, camType) => {
      if (!stream) return;

      const mimeType = MediaRecorder.isTypeSupported("video/webm; codecs=vp8")
        ? "video/webm; codecs=vp8"
        : "video/mp4";

      let chunkCounter = 0; // Track chunk index
      let totalChunks = 0; // Track total chunks recorded

      if (chunkCounter === 0) {
        try {
          const url = `/v1/content/getVideoCount?contentId=${contentId}`;
          const res = await noBaseUrlInstance.get(url);
          chunkCounter = res.data.data;
        } catch (err) {
          console.log("error in getting chunk counter", err);
        }
      }

      const recordChunk = () => {
        const recorder = new MediaRecorder(stream, { mimeType });
        let recordedChunks = []; // Store chunks for the session

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.push(event.data);
          }
        };

        recorder.onstop = () => {
          const isSilentChunk = !stream.getAudioTracks()[0].enabled;
          const blob = new Blob(recordedChunks, { type: mimeType });

          chunkCounter++;
          totalChunks++;

          const chunkData = {
            chunk: blob,
            index: chunkCounter,
            filename,
            totalChunks,
            camType,
            isSilent: isSilentChunk,
          };
          chunkQueue.current.push(chunkData);
          processQueue();
        };

        recorder.start();
        setTimeout(() => {
          recorder.stop();
          recordChunk();
        }, 10000);
      };

      recordChunk();
    },
    [processQueue]
  );

  const startStreamAndRecord = useCallback(async () => {
    console.log("Starting stream and recording...");

    try {
      const constraints = {
        video: { facingMode: isEnableFlip ? "environment" : "user" },
        audio: true,
      };

      // {
      //   echoCancellation: true,
      //   noiseSuppression: true,
      //   autoGainControl: true, // Automatically adjusts the microphone sensitivity
      //   sampleRate: 48000, // Standard WebRTC sample rate
      //   channelCount: 2, // Stereo
      // },

      const camType = isEnableFlip ? "B" : "F";

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      const audioTracks = stream.getAudioTracks()[0];
      if (audioTracks) {
        audioTracks.enabled = isEnableAudio;
      }

      totalChunksRef.current = 0;
      chunkIndexRef.current = 0;
      stopUploadRef.current = false;

      startRecording(stream, filenameRef.current, camType);
      // startRecording();

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      streamRef.current = stream;
      setLocalStream(stream);

      socketManager.liveStreamStarted(mainroomname);

      setRecording(true);
    } catch (error) {
      console.error("Stream and recording error:", error);
    }
  }, [isEnableAudio, isEnableFlip, mainroomname, startRecording]);

  const handleSwitchCamera = useCallback(async () => {
    if (isSwitching || !localStream) return;
    try {
      setIsSwitching(true);
      setHideSwitch(true);

      const timerId = setTimeout(() => {
        setHideSwitch(false);
      }, 12000);

      const device = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = device.filter(
        (device) => device.kind === "videoinput"
      );

      const currentDeviceId = localStream
        .getVideoTracks()[0]
        .getSettings().deviceId;
      const newDevice = videoDevices.find(
        (device) => device.deviceId !== currentDeviceId
      );

      if (!newDevice) {
        console.error("No other camera found to switch to.");
        setIsSwitching(false);
        clearTimeout(timerId);
        return;
      }

      localStream.getVideoTracks().forEach((track) => track.stop());
      // setLocalStream(null);
      localVideoRef.current.srcObject = null;

      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive" &&
        recording
      ) {
        console.log("Stopping recording before switching camera...");
        mediaRecorderRef.current.stop();
        stopUploadRef.current = true;
      }

      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: newDevice.deviceId } },
        audio: true,
      });

      const audioTracks = newStream.getAudioTracks()[0];
      if (audioTracks) {
        audioTracks.enabled = mute;
      }

      const newVideoTrack = newStream.getVideoTracks()[0];
      const oldVideoTrack = localStream.getVideoTracks()[0];

      localStream.removeTrack(oldVideoTrack);
      localStream.addTrack(newVideoTrack);

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = localStream;
      }

      streamRef.current = localStream;
      // setLocalStream(newStream);

      Object.keys(peerConnections.current).forEach(async (userId) => {
        const pc = peerConnections.current[userId];
        if (pc) {
          const sender = pc.getSenders().find((s) => s.track.kind === "video");
          if (sender) {
            await sender.replaceTrack(newVideoTrack);
          }
        }
      });

      const camType = isBack ? "F" : "B";

      if (recording) {
        console.log("Restarting recording after camera switch...");
        // totalChunksRef.current = 1;
        // chunkIndexRef.current = 1;
        stopUploadRef.current = false;
        startRecording(localStream, filenameRef.current, camType);
      }

      setIsBack((prev) => !prev);
      setIsSwitching(false);
    } catch (error) {
      console.error("Error switching camera and restarting recording: ", error);
      // await startStreamAndRecord(filenameRef.current, "1233");
      setIsSwitching(false);
    }
  }, [isBack, isSwitching, localStream, mute, recording, startRecording]);

  const stopRecording = useCallback(async () => {
    setIsLoading(true);
    setEndLoading(true);
    // socketManager.onChangedEvent(true);
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      // mediaRecorderRef.current.requestData();
      mediaRecorderRef.current.stop();

      // mediaRecorderRef.current.ondataavailable = async (event) => {
      //   // This will handle the last emitted chunk when stopping
      //   if (event.data.size > 0) {
      //     chunkIndexRef.current += 1; // Increment chunk index for the final chunk
      //     totalChunksRef.current += 1; // Update total chunks count

      //     const chunkData = {
      //       chunk: event.data,
      //       index: chunkIndexRef.current, // Final chunk index
      //       filename: filenameRef.current,
      //       totalChunks: totalChunksRef.current, // Total chunks
      //       camType: "B",
      //     };

      //     // Push the final chunk into the queue and process it
      //     chunkQueue.current.push(chunkData);
      //     await processRemainingChunks(); // Upload remaining chunks
      //   }
      // };
    }

    closeAllPeerConnections(peerConnections);
    localStream.getTracks().forEach((track) => {
      track.stop();
    });
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = null;
      }
    }
    // socketManager.onChangedEvent(true);
    setRecording(false);
    // stopUploadRef.current = true;

    const url = `/v1/content/updateContentStatus?id=${contentId}&eventStatus=End`;
    const eventStatus = await noBaseUrlInstance.put(url);

    console.log(eventStatus);

    if (eventStatus.status === 200) {
      socketManager.onChangedEvent(true);
      // should stay commented...
      // await axios.post("/api/chunk-merger", {
      //   filename: filenameRef.current,
      //   totalChunks: totalChunksRef.current,
      //   contentTd: contentId,
      //   chunkNumber: chunkIndexRef.current,
      //   endStream: "completed",
      // });
    }
  }, [closeAllPeerConnections, contentId, localStream]);

  const handleArtistBackPressed = useCallback(
    async (msg) => {
      if (msg && msg.length > 0) {
        await stopRecording();
      }
    },
    [stopRecording]
  );

  const handleArtistDisconnected = useCallback(async (message) => {
    socketManager.socket.disconnect();
    // navigate("/end-stream");
    setEndLoading(false);
  }, []);

  useEffect(() => {
    socketManager.newUserJoinedInMainRoom(handleJoinedRoom);
    socketManager.onUpdategoLive(handlegoLiveOffer);
    socketManager.receviedAnswer(handleReceviedAnswer);
    socketManager.receviedIceCandidate(handleReceviedCandidate);
    socketManager.onUpdatedUserList(handleUserUpdateList);
    socketManager.onUpdateLiveUserList(handleLiveUserUpdateList);
    socketManager.onArtistDisconnected(handleArtistDisconnected);
    // socketManager.updateUserDisconnected(handleUpdateUserDisconnected)
    // socketManager.createMainRoom("", artistId, true)
    socketManager.onBackPreesArtist(handleArtistBackPressed);

    return () => {
      socketManager.socket.off("update-user-list", handleUserUpdateList);
      socketManager.socket.off("newUserJoined", handleJoinedRoom);
      socketManager.socket.off("answer", handleReceviedAnswer);
      socketManager.socket.off("ice-candidate", handleReceviedCandidate);
      socketManager.socket.off("updategoLive", handlegoLiveOffer);
      socketManager.socket.off(
        "live-update-user-list",
        handleLiveUserUpdateList
      );
      socketManager.socket.off("artist-disconnected", handleArtistDisconnected);
      // socketManager.socket.off('update-user-disconnected', handleUpdateUserDisconnected);

      // webRTCManagerLive.cleanUp();
      socketManager.socket.off("onBackPressArtist", handleArtistBackPressed);
    };
  }, [
    handleJoinedRoom,
    handlegoLiveOffer,
    handleReceviedAnswer,
    handleReceviedCandidate,
    handleUserUpdateList,
    handleLiveUserUpdateList,
    handleArtistDisconnected,
    handleArtistBackPressed,
  ]);

  const handleSequentialMerge = useCallback(async () => {
    try {
      await axios.post("/api/convert-to-S3", {
        filename: filenameRef.current,
        contentTd: contentId,
        noOfVideos: videoNo,
      });
    } catch (error) {
      console.error("Error in merging videos:", error);
    }
  }, [contentId, videoNo]);

  useEffect(() => {
    if (isLoading) {
      const interval = setTimeout(async () => {
        setIsLoading(false);
        socketManager.onChangedEvent(true);

        // await handleSequentialMerge();
        setVideoNo(0);

        // socketManager.onChangedEvent(true);
        socketManager.onArtistDisconnect(mainroomname);
        // navigate("/end-stream");
      }, 2000); // 2 seconds

      // Cleanup function
      return () => clearTimeout(interval);
    }
  }, [handleSequentialMerge, isLoading, mainroomname, navigate]);

  useEffect(() => {
    if (isStreamStrart && Count === 0) {
      socketManager.createMainRoom(mainroomname, artistId, true);
      startStreamAndRecord();
      Count++;
    }
  }, []);

  useEffect(() => {
    if (localStream && userList.length > 0) {
      userList.forEach(async (user) => {
        let previousUser = previousSended.some(
          (u) => u.userSocketId === user.userSocketId
        );
        if (!previousUser) {
          setPreviousSended((previous) => [...previous, user]);
          await createPeerConnectionCreator(
            localStream,
            user.userSocketId,
            mainroomname,
            peerConnections
          );
        }
      });
    }
  }, [
    createPeerConnectionCreator,
    localStream,
    mainroomname,
    previousSended,
    userList,
  ]);

  useEffect(() => {
    if (artistId) {
      const existArtistId = asyncStorage.getItem("artistId");
      if (existArtistId) {
        if (existArtistId !== artistId) {
          asyncStorage.removeItem("artistId");
        }
      }
      asyncStorage.setItem("artistId", artistId);
    }
  }, [artistId]);

  return (
    <div className="container">
      {endLoading ? (
        <ReactLoading
          type="spinningBubbles"
          color="#BB8A01"
          height={64}
          width={64}
        />
      ) : (
        <>
          <div className="top_live_screen">
            <video
              ref={localVideoRef}
              autoPlay
              muted
              playsInline
              webkit-playsinline
              className="video"
              style={{ transform: isBack ? "scaleX(1)" : "scaleX(-1)" }}
            />
          </div>
          {recording && (
            <div className="bottom_tabs_action">
              {mute ? (
                <button className="mic_on" onClick={handleAudio}>
                  <Micon />
                </button>
              ) : (
                <button className="mic_on" onClick={handleAudio}>
                  <MicOff />
                </button>
              )}

              <button className="end-stream" onClick={stopRecording}>
                End Live
              </button>
              {/* 
            {!hideSwitch && (
              <button className="switch_camera" onClick={handleSwitchCamera}>
                <SwitchCamera />
              </button>
            )} */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MediaRecord;
